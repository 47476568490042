#wrapper {
    max-width: 1024px;
    margin: auto;
    position: relative;
}

app-root {
    font-size: 14px;
}

agm-map .agm-info-window-content {
    max-width: 20em;
}
